import React from 'react'
import pin from '../../images/pin.svg'
import PropTypes from 'prop-types'

const ContactField = ({ header, onSubmit }) =>
  <div className='contact-with-field-field'>
    <img
      className='pin-icon'
      src={pin}
      alt='Pin icon'
    />
    <div className='location'>
      <div className='location__header'>{header}</div>
      <form
        onSubmit={event => onSubmit(event)}
        className='location-form'
      >
        <input
          name='location'
          className='location-form__input'
          placeholder='PLZ oder Ort...'
        />
        <input
          type='submit'
          value=' '
          className='location-form__button'
        />
      </form>
    </div>
  </div>

export default ContactField

ContactField.propTypes = {
  header: PropTypes.string,
}

ContactField.defaultProps = {
  header: '',
}
