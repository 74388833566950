import whiteArrowIcon from '../../images/whiteArrow.svg'
import React from 'react'

const PrevArrow = ({ onClick, className, style }) =>
  <div
    role='button'
    className={className + ' prevArrow'}
    style={{ ...style }}
    onClick={onClick}
    onKeyDown={onClick}
    tabIndex={0}
  >
    <img
      src={whiteArrowIcon}
      alt='White arrow'
    />
  </div>

const NextArrow = ({ onClick, className, style }) =>
  <div
    role='button'
    className={className + ' nextArrow'}
    style={{ ...style }}
    onClick={onClick}
    onKeyDown={onClick}
    tabIndex={0}
  >
    <img
      src={whiteArrowIcon}
      alt='White arrow'
    />
  </div>

export const sliderConfig = {
  infinite: true,
  speed: 500,
  className: 'center',
  lazyLoad: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  centerMode: true,
  centerPadding: '20px',
  prevArrow: <PrevArrow/>,
  nextArrow: <NextArrow/>,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '20px'
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false
      }
    },
    {
      breakpoint: 792,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false
      }
    },
  ]
}
