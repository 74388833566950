import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import PropTypes from 'prop-types'
import { LinkButtonPropType } from '../propTypes'
import classNames from 'classnames'
import ContactDetails from './ContactDetails'
import { Link } from 'gatsby'
import { Controller, Scene } from 'react-scrollmagic'

const getGradientContactClassName = (variant) => classNames(
  'contact',
  {
    'contact-teal': variant === 'teal'
  },
)

const getBackgroundColor = (variant) => variant === 'teal' ? 'teal' : ''

const Contact = ({ header, phoneNumber, openingTime, closingTime, button, variant }) =>
  <SectionWrapper
    className={getGradientContactClassName(variant)}
    backgroundColor={getBackgroundColor(variant)}
  >
    <ContactDetails
      header={header}
      phoneNumber={phoneNumber}
      openingTime={openingTime}
      closingTime={closingTime}
    />
    <Controller>
      <Scene
        classToggle='contact-button-active'
        reverse={false}
        triggerHook='0.8'
      >
        <Link
          to={button?.href}
          className='contact-button'
        >
          {button?.label}
        </Link>
      </Scene>
    </Controller>
  </SectionWrapper>

export default Contact

Contact.propTypes = {
  header: PropTypes.string,
  phoneNumber: PropTypes.string,
  openingTime: PropTypes.string,
  closingTime: PropTypes.string,
  button: LinkButtonPropType,
  variant: PropTypes.oneOf(['', 'teal'])
}

Contact.defaultProps = {
  header: '',
  phoneNumber: '',
  openingTime: '',
  closingTime: '',
  button: {},
  variant: ''
}
