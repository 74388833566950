import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import PropTypes from 'prop-types'
import Tile from './Tile'
import { TilePropType } from '../propTypes'
import { Controller, Scene } from 'react-scrollmagic'

const Tiles = ({ tiles }) =>
  <Controller>
    <Scene
      classToggle='tiles-active'
      reverse={false}
      triggerHook='0.8'
    >
      <div>
        <SectionWrapper
          className='tiles'
          backgroundColorMd='gradient-light-to-dark-teal'
        >
          {tiles.map((tile, index) =>
            <Tile key={index} {...tile} />
          )}
        </SectionWrapper>
      </div>
    </Scene>
  </Controller>
export default Tiles

Tiles.propTypes = {
  tiles: PropTypes.arrayOf(TilePropType).isRequired
}
