import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import Paragraph from '../Paragraph/Paragraph'
import Step from './Step'
import PropTypes from 'prop-types'
import { HeaderWithDescriptionPropType } from '../propTypes'
import { stepsData } from '../consts'

const Steps = ({ header, description, steps, button }) => {
  steps = steps.map((step, index) => index === 3
    ? ({ ...stepsData.steps[index], ...step, ...button })
    : ({ ...stepsData.steps[index], ...step }))

  return <SectionWrapper className='steps-container'>
    <div className='steps-container__header'>{header}</div>
    <Paragraph
      className='steps-container__body'
      text={description}
    />
    <div className='steps'>
      {
        steps.map((step, index) =>
          <Step
            key={index}
            {...step}
            index={index}
          />)
      }
    </div>
  </SectionWrapper>
}

export default Steps

Steps.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(HeaderWithDescriptionPropType).isRequired
}
