import React from 'react'
import phoneIcon from '../../images/telephone2.svg'
import PropTypes from 'prop-types'

const ContactDetails = ({ header, phoneNumber, openingTime, closingTime }) =>
  <div className='contact-details'>
    <div className='contact-details__header'>{header}</div>
    <div className='contact-details__contact'>
      <img
        src={phoneIcon}
        alt='Phone icon'
      />
      <div className='contact-info'>
        <a
          href={`tel:${phoneNumber}`}
          className='contact-info__phone-number'
        >
          {phoneNumber}
        </a>
        <div className='contact-info__time'>
          Mo - Fr {openingTime} - {closingTime} Uhr
        </div>
      </div>
    </div>
  </div>

export default ContactDetails

ContactDetails.propTypes = {
  header: PropTypes.string,
  phoneNumber: PropTypes.string,
  openingTime: PropTypes.string,
  closingTime: PropTypes.string,
}

ContactDetails.defaultProps = {
  header: '',
  phoneNumber: '',
  openingTime: '',
  closingTime: '',
}
