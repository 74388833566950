import React from 'react'
import PropTypes from 'prop-types'
import { StrapiImagePropType } from '../propTypes'
import { Link } from 'gatsby'
import arrow from '../../images/arrowRed.svg'

const Tile = ({ image, header, href }) =>
  <Link
    className='tile'
    to={href}
  >
    <img
      src={image.url}
      alt={image.alternativeText}
    />
    <div className='tile-text'>
      <div className='tile-text__header'>{header}</div>
      <div className='tile-cta'>
        <span>
        Mehr erfahren
        </span>
        <img
          src={arrow}
          alt='arrow icon'
        />
      </div>
    </div>
  </Link>

export default Tile

Tile.propTypes = {
  image: StrapiImagePropType.isRequired,
  header: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
}
