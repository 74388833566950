import React, { useEffect } from 'react'
import mapboxgl from '!mapbox-gl'
import { badenBadenPolygon, heidelbergPolygon, karlsruhePolygon } from './polygons'

mapboxgl.accessToken = process.env.GATSBY_MAPBOX_ACCESS_TOKEN

const useMap = (currentLocationCords) => {
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [10.4515, 51.1657],
      zoom: 4
    })
    map.on('load', () => {
      map.addSource('heidelberg', {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'geometry': {
            'type': 'Polygon',
            'coordinates': heidelbergPolygon
          }
        }
      })
      map.addLayer({
        'id': 'heidelberg',
        'type': 'fill',
        'source': 'heidelberg', // reference the data source
        'layout': {},
        'paint': {
          'fill-color': '#0080ff', // blue color fill
          'fill-opacity': 0.5
        }
      })
      map.addLayer({
        'id': 'heidelbergOutline',
        'type': 'line',
        'source': 'heidelberg',
        'layout': {},
        'paint': {
          'line-color': '#000',
          'line-width': 1
        }
      })
      map.addSource('karlsruhe', {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'geometry': {
            'type': 'Polygon',
            'coordinates': karlsruhePolygon
          }
        }
      })
      map.addLayer({
        'id': 'karlsruhe',
        'type': 'fill',
        'source': 'karlsruhe', // reference the data source
        'layout': {},
        'paint': {
          'fill-color': '#0080ff', // blue color fill
          'fill-opacity': 0.5
        }
      })
      map.addLayer({
        'id': 'karlsruheOutline',
        'type': 'line',
        'source': 'karlsruhe',
        'layout': {},
        'paint': {
          'line-color': '#000',
          'line-width': 1
        }
      })
      map.addSource('badenBaden', {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'geometry': {
            'type': 'Polygon',
            'coordinates': badenBadenPolygon
          }
        }
      })
      map.addLayer({
        'id': 'badenBaden',
        'type': 'fill',
        'source': 'badenBaden', // reference the data source
        'layout': {},
        'paint': {
          'fill-color': '#0080ff', // blue color fill
          'fill-opacity': 0.5
        }
      })
      map.addLayer({
        'id': 'badenBadenOutline',
        'type': 'line',
        'source': 'badenBaden',
        'layout': {},
        'paint': {
          'line-color': '#000',
          'line-width': 1
        }
      })
      new mapboxgl.Marker()
        .setLngLat([8.241605, 48.758685])
        .addTo(map)
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML(
              `<h3>Baden-Baden und Rastatt
                (zuständig für Baden-Baden und Rastatt</h3>
                <p style='margin:0'>Tel: 07221 - 4083340</p>
                <p style='margin:0'>Fax: 07221 - 4083341</p>
                <p style='margin:0'>E-Mail: info@senprima.de</p>
              `
            )
        )
      new mapboxgl.Marker()
        .setLngLat([8.458122, 48.99373])
        .addTo(map)
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML(
              `<h3>Karlsruhe
                (zuständig für Großraum Karlsruhe)</h3>
                <p style='margin:0'>Tel: 0721 - 9873220</p>
                <p style='margin:0'>Fax: 0721 - 987322 - 99</p>
                <p style='margin:0'>E-Mail: info@senprima.de</p>
              `
            )
        )
      new mapboxgl.Marker()
        .setLngLat([8.584562301635742, 49.37730026245117])
        .addTo(map)
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML(
              `<h3>Heidelberg und Schwetzingen
                (zuständig für Raum Heidelberg und Schwetzingen)</h3>
                <p style='margin:0'>Tel: 06202 - 7663996</p>
                <p style='margin:0'>Fax: 06202 - 7663997</p>
                <p style='margin:0'>E-Mail: info@senprima.de</p>
              `
            )
        )
    })

    new mapboxgl.Marker({ color: '#FF0000' })
      .setLngLat(currentLocationCords)
      .addTo(map)
      .setPopup(
        new mapboxgl.Popup({ offset: 25 }) // add popups
          .setHTML(
            `<h3>Ihr Standort</h3>`
          )
      )
      .togglePopup()

    return () => map.remove()
  }, [currentLocationCords])
}

export default useMap
