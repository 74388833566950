import starImage from '../images/star.svg'
import halfStarImage from '../images/halfStar.svg'
import emptyStarImage from '../images/emptyStar.svg'
import React from 'react'
import arrowPointingDownIcon from '../images/careerIcons/arrowPointingDown.svg'
import shortArrowPointingDownIcon from '../images/careerIcons/shortArrowPointingDown.svg'
import longShortArrowPointingDownIcon from '../images/careerIcons/longArrowPointingDown.svg'

export const getStarsNumber = (rating) => {
  const starsToDisplay = []
  for (let i = 0; i < 5; i++) {
    if (rating - 1 >= i) {
      starsToDisplay.push(<img
        src={starImage}
        alt='star'
      />)
    } else if (rating - i >= 0.5) {
      starsToDisplay.push(<img
        src={halfStarImage}
        alt='half star'
      />)
    } else {
      starsToDisplay.push(<img
        src={emptyStarImage}
        alt='empty star'
      />)
    }
  }
  return starsToDisplay
}

export const getArrowForNumber = (index) => {
  switch (true) {
    case index === 0 :
      return <img
        src={longShortArrowPointingDownIcon}
        alt='Arrow pointing down'
      />
    case index === 1 :
      return <img
        src={arrowPointingDownIcon}
        alt='Arrow pointing down'
      />
    case index === 2:
      return <img
        src={shortArrowPointingDownIcon}
        alt='Short arrow pointing down'
      />
    default:
      return
  }
}

export const getJobOfferThumbnailsToDisplay = (activeLocation, jobOffers) =>
  activeLocation === null
    ? jobOffers
    : jobOffers.filter(jobOffer => jobOffer.location === activeLocation)
