import React from 'react'
import PropTypes from 'prop-types'

const TabService = ({ name, price }) => <div className='service'>
  <div className='service__name'>{name}</div>
  <div className='service__price'>{price}</div>
</div>

export default TabService

TabService.propTypes = {
  name: PropTypes.string,
  price: PropTypes.string
}

TabService.defaultProps = {
  name: '',
  price: '',
}
