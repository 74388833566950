import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { LinkButtonPropType, StrapiImageSharpPropType } from '../propTypes'
import { Link } from 'gatsby'

const WelcomingSectionDesktop = ({ image, header, description, buttons, information, }) => {
  const gatsbyImageData = image?.localFile?.childImageSharp?.gatsbyImageData

  return <div className='welcoming-screen'>
    <GatsbyImage
      className='welcoming-screen__image'
      alt={image?.alternativeText}
      image={gatsbyImageData}
    />
    <div className='welcoming-screen-text'>
      <div className='welcoming-screen-text__header'>{header}</div>
      <div className='welcoming-screen-text__body'>{description}</div>
      <div className='welcoming-screen-buttons'>
        {buttons.map((button, index) =>
          <Link
            className='button'
            key={index}
            to={button.href}
          >
            {button.label}
          </Link>)}
      </div>
    </div>
    <div className='information'>{information}</div>
  </div>
}

export default WelcomingSectionDesktop

WelcomingSectionDesktop.propTypes = {
  image: StrapiImageSharpPropType,
  header: PropTypes.string,
  description: PropTypes.string,
  buttons: PropTypes.arrayOf(LinkButtonPropType),
  information: PropTypes.string
}

WelcomingSectionDesktop.defaultProps = {
  image: {},
  header: '',
  description: '',
  buttons: [],
  information: ''
}
