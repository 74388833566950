import React, { useCallback, useState } from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import PropTypes from 'prop-types'
import ContactWithFieldDetails from './ContactWithFieldDetails'
import ContactField from './ContactField'
import Map from '../Map/Map'

const ContactWithField = ({
  openingTime,
  closingTime,
  phoneNumber,
  header
}) => {
  const [currentLocationCords, setCurrentLocationCords] = useState(null)

  const getCordsForText = useCallback((event) => {
    event.preventDefault()
    const location = event.target.location.value
    if (location === '') {
      return
    }
    fetch(`https://nominatim.openstreetmap.org/search.php?q=${location.split(' ').join('+')}&format=jsonv2`)
      .then(
        response => response.json().then(data => {
          setCurrentLocationCords([data?.[0]?.lon, data?.[0]?.lat])
        }),
        error => {
          console.log(error)
        }
      )
  }, [])

  return <>
    <SectionWrapper
      className='contact-with-field'
      boxShadow
    >
      <ContactWithFieldDetails
        openingTime={openingTime}
        closingTime={closingTime}
        phoneNumber={phoneNumber}
      />
      <ContactField
        onSubmit={getCordsForText}
        header={header}
      />
    </SectionWrapper>
    {currentLocationCords && <Map currentLocationCords={currentLocationCords} />}
  </>
}

export default ContactWithField

ContactWithField.propTypes = {
  openingTime: PropTypes.string,
  closingTime: PropTypes.string,
  phoneNumber: PropTypes.string,
  header: PropTypes.string,
}

ContactWithField.defaultProps = {
  openingTime: '',
  closingTime: '',
  phoneNumber: '',
  header: '',
}
