import React from 'react'
import Contact from '../components/Contact/Contact'
import Layout from '../components/Layout/Layout'
import WelcomingSection from '../components/WelcomingSection/WelcomingSection'
import VideoWithText from '../components/VideoWithText/VideoWithText'
import Introduction from '../components/Introduction/Introduction'
import Tiles from '../components/Tiles/Tiles'
import Opinions from '../components/Opinions/Opinions'
import { opinionsData } from '../components/consts'
import Tabs from '../components/Tabs/Tabs'
import ContactWithField from '../components/ContactWithField/ContactWithField'
import Steps from '../components/Steps/Steps'
import { graphql } from 'gatsby'

const HomePage = ({ data }) => {
  const {
    strapiCompanyInfo: companyInfo,
    strapiHomePage: {
      description,
      welcomingSection,
      contact,
      introduction,
      tiles,
      videoWithText,
      gradientContact,
      tabs,
      steps
    }
  } = data

  return <Layout description={description}>
    <WelcomingSection {...welcomingSection} />
    <ContactWithField {...contact} {...companyInfo} />
    <Introduction {...introduction} />
    <Tiles tiles={tiles} />
    {/*<VideoWithText {...videoWithText} />*/}
    <Steps {...steps} />
    <Tabs {...tabs} />
    <Contact {...gradientContact} {...companyInfo} />
    <Opinions {...opinionsData} />
  </Layout>
}

export default HomePage

export const query = graphql`
  query HomePageQuery {
    strapiHomePage {
      id
      description
      ...welcomingSectionFragment
      ...contactFragment
      ...introductionFragment
      ...tilesFragment
      ...videoWithTextFragment
      ...contactFragment
      ...tabsFragment
      ...gradientContactFragment
      ...stepsFragment
    }
    ...companyInfoFragment
  }
 `
