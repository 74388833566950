import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import Slider from 'react-slick'
import Opinion from './Opinion'
import { getStarsNumber } from '../helpers'
import PropTypes from 'prop-types'
import { sliderConfig } from './consts'
import { OpinionPropType } from '../propTypes'

const Opinions = ({ header, opinions, percentRating, rating, numberOfOpinions, buttonLabel, buttonHref }) =>
  <>
    <div className='opinions-header'>{header}</div>
    <SectionWrapper
      boxShadow
      className='opinions'
      backgroundColor='horizontal-dark-teal-gradient'
    >
      <Slider {...sliderConfig}>
        {opinions?.map((opinion, index) => <Opinion key={index} {...opinion} />)}
      </Slider>
    </SectionWrapper>
    <div className='opinions-percent-rating'>{percentRating}</div>
    <div className='opinions-button'>
      <a href={buttonHref}>{buttonLabel}</a>
    </div>
    <div className='opinions-star-rating'>
      <div className='opinions-star-rating__stars'>
        {getStarsNumber(rating)}
      </div>
      <div className='opinions-star-rating__text'>{rating} von 5 basierend auf {numberOfOpinions} Bewertungen</div>
    </div>
    <div className='opinions-button-mobile'>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={buttonHref}
      >
        {buttonLabel}
      </a>
    </div>
  </>

export default Opinions

Opinions.propTypes = {
  header: PropTypes.string,
  opinions: PropTypes.arrayOf(OpinionPropType),
  percentRating: PropTypes.string,
  rating: PropTypes.number,
  numberOfOpinions: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonHref: PropTypes.string
}

Opinions.defaultProps = {
  header: '',
  opinions: [],
  percentRating: '',
  rating: '',
  numberOfOpinions: '',
  buttonLabel: '',
  buttonHref: '',
}
