export const heidelbergPolygon = [
  [
    [
      8.50073,
      49.331402
    ],
    [
      8.508366,
      49.307638
    ],
    [
      8.588948,
      49.295944
    ],
    [
      8.686951,
      49.27927
    ],
    [
      8.753262,
      49.303215
    ],
    [
      8.720342,
      49.374443
    ],
    [
      8.738204,
      49.433506
    ],
    [
      8.696548,
      49.48704
    ],
    [
      8.699319,
      49.546773
    ],
    [
      8.665899,
      49.57214
    ],
    [
      8.440734,
      49.554114
    ],
    [
      8.470771,
      49.532383
    ],
    [
      8.473566,
      49.511495
    ],
    [
      8.448555,
      49.500237
    ],
    [
      8.458413,
      49.484009
    ],
    [
      8.467241,
      49.469115
    ],
    [
      8.453446,
      49.451802
    ],
    [
      8.480308,
      49.448571
    ],
    [
      8.511469,
      49.440981
    ],
    [
      8.513289,
      49.426144
    ],
    [
      8.509792,
      49.395601
    ],
    [
      8.498119,
      49.353228
    ],
    [
      8.50073,
      49.331402
    ]
  ]
]
export const karlsruhePolygon = [
  [
    [
      8.24613,
      48.95524
    ],
    [
      8.322794,
      48.94003
    ],
    [
      8.467223,
      48.898797
    ],
    [
      8.537932,
      48.958486
    ],
    [
      8.595195,
      48.994563
    ],
    [
      8.563856,
      49.037309
    ],
    [
      8.552653,
      49.086274
    ],
    [
      8.468884,
      49.122931
    ],
    [
      8.392943,
      49.161645
    ],
    [
      8.360151,
      49.088143
    ],
    [
      8.319128,
      49.033922
    ],
    [
      8.282984,
      48.982997
    ],
    [
      8.24613,
      48.95524
    ]
  ],
]

export const badenBadenPolygon = [
  [
    [
      8.127308,
      48.683395
    ],
    [
      8.078204,
      48.710437
    ],
    [
      8.149493,
      48.789035
    ],
    [
      8.116912,
      48.819975
    ],
    [
      8.167659,
      48.890601
    ],
    [
      8.202204,
      48.922381
    ],
    [
      8.243889,
      48.950624
    ],
    [
      8.32113,
      48.935348
    ],
    [
      8.379277,
      48.869988
    ],
    [
      8.409651,
      48.766351
    ],
    [
      8.127308,
      48.683395
    ]
  ]
]
