import React from 'react'
import { getStarsNumber } from '../helpers'
import PropTypes from 'prop-types'

const Opinion = ({ name, opinion, rating }) =>
  <div className='opinion'>
    <div className='opinion__name'>{name}</div>
    <span className='opinion__opinion'>{opinion.slice(0, 160)}
      <a href='https://www.google.com/maps/place/Senprima+GmbH+Karlsruhe/@48.9937403,8.4559432,17z/data=!4m7!3m6!1s0x479709eae2d8ce25:0x6005dbfb36cce4d1!8m2!3d48.9937403!4d8.4581319!9m1!1b1'>
        mehr...
      </a>
    </span>
    <div className='opinion__stars'>
      {getStarsNumber(rating)}
    </div>
  </div>

export default Opinion

Opinion.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
  name: PropTypes.string,
  opinion: PropTypes.string,
  rating: PropTypes.number,
}

Opinion.defaultProps = {
  image: '',
  alt: '',
  name: '',
  opinion: '',
  rating: '',
}
