import React from 'react'
import PropTypes from 'prop-types'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import senprimaLogoGray from '../../images/logoSenprimaGray.svg'
import { LinkButtonPropType } from '../propTypes'

const VideoWithText = ({ videoLink, videoTitle, header, headline, description, button }) =>
  <>
    <div className='gradient-bar-mobile'/>
    <SectionWrapper
      backgroundColorMd='teal'
      backgroundColor='horizontal-dark-teal-gradient'
      boxShadow
      className='iframe-with-text'
    >
      <iframe
        src={videoLink}
        className='iframe-desktop'
        title={videoTitle}
        width='300'
        height='200'
      />
      <div className='background-frame-mobile'/>
      <div className='iframe-with-text-right'>
        <img
          className='iframe-logo'
          src={senprimaLogoGray}
          alt='Senrpima logo'
        />
        <div className='iframe-with-text-right__header'>{header}</div>
        <div className='iframe-with-text-right__headline'>{headline}</div>
        <div className='iframe-with-text-right__description'>{description}</div>
        <iframe
          className='iframe-mobile'
          src={videoLink}
          title={videoTitle}
          width='300'
          height='200'
        />
        <a
          href={button?.href}
          className='iframe-with-text-right__button'
        >
          {button?.label}
        </a>
      </div>
    </SectionWrapper>
    <div className='iframe-with-text-gradient-bar'/>
  </>

export default VideoWithText

VideoWithText.propTypes = {
  videoLink: PropTypes.string,
  videoTitle: PropTypes.string,
  header: PropTypes.string,
  headline: PropTypes.string,
  description: PropTypes.string,
  button: LinkButtonPropType
}

VideoWithText.defaultProps = {
  videoLink: '',
  videoTitle: '',
  header: '',
  headline: '',
  description: '',
  button: {}
}

