import React from 'react'
import blackPhoneIcon from '../../images/blackPhone.webp'
import grayPhoneIcon from '../../images/grayPhone.svg'
import PropTypes from 'prop-types'

const ContactWithFieldDetails = ({ openingTime, closingTime, phoneNumber }) =>
  <div className='contact-with-field-details'>
    <div className='hours'>
      <img
        className='phone-icon-0'
        src={blackPhoneIcon}
        alt='Black phone icon'
      />
      <img
        className='phone-icon-1'
        src={grayPhoneIcon}
        alt='Gray phone icon'
      />
      <div className='time'>
        <div className='time__header'>Rufen Sie uns an:</div>
        <div className='time__body'>Mo - Fr {openingTime} - {closingTime} Uhr</div>
      </div>
    </div>
    <a
      className='contact-with-field-details__phone-number'
      href={`tel:${phoneNumber}`}
    >
      {phoneNumber}
    </a>
  </div>

export default ContactWithFieldDetails

ContactWithFieldDetails.propTypes = {
  openingTime: PropTypes.string,
  closingTime: PropTypes.string,
  phoneNumber: PropTypes.string,
}

ContactWithFieldDetails.defaultProps = {
  openingTime: '',
  closingTime: '',
  phoneNumber: '',
}
