import React from 'react'
import { Controller, Scene } from 'react-scrollmagic'
import StepArrow from './StepArrow'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Step = (props) =>
  <div className={`step step-${props.index} `}>
    {(props.arrowOnLeft && props.arrow) && <StepArrow image={props.arrow} />}
    <Controller>
      <Scene
        duration={300}
        reverse={false}
        triggerHook='onCenter'
      >
        {progress => (
          <div
            style={{ opacity: progress }}
            className='image-with-text'
          >
            <div className='number'> {props.index + 1}.</div>
            <img
              className='image-0'
              src={props.roundImage}
              alt={props.roundImageAlt}
            />
            {props.attachedImage && <img
              className='image-1'
              src={props.attachedImage}
              alt={props.attachedImageAlt}
            />}
            <div className='step-text'>
              <div className='step-header'>{props.header}</div>
              <div className='step-body'>{props.description}</div>
              {props?.label &&
                <Link
                  className='step-button'
                  to={props?.href}
                >
                  {props?.label}
                </Link>
              }
            </div>
          </div>
        )}
      </Scene>
    </Controller>
    {(!props.arrowOnLeft && props.arrow) && <StepArrow image={props.arrow} />}
  </div>

export default Step

Step.propTypes = {
  roundImage: PropTypes.string.isRequired,
  roundImageAlt: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  attachedImage: PropTypes.string,
  attachedImageAlt: PropTypes.string,
  arrowOnLeft: PropTypes.bool,
  arrow: PropTypes.string,
  href: PropTypes.string,
  label: PropTypes.string
}

Step.defaultProps = {
  roundImage: '',
  roundImageAlt: '',
  attachedImage: '',
  attachedImageAlt: '',
  arrowOnLeft: false,
  arrow: '',
}
