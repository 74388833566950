import React, { useState } from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import PropTypes from 'prop-types'
import Tab from './Tab'
import { TabPropType } from '../propTypes'

const Tabs = ({ header, headline, tabs }) => {
  const [activeTab, setActiveTab] = useState(0)

  const getTabClassName = (index) =>
    `tab-name tab-name--${index === activeTab ? 'active' : index > activeTab ? index - 1 : index}`

  return <>
    <div className='gradient-bar-teal'/>
    <SectionWrapper className='tabs'>
      <div className='tabs__header '>{header}</div>
      <div className='tabs__headline'>{headline}</div>
      <div className='tab-description-mobile'>
        {tabs[activeTab].description}
      </div>
      <div className='tabs__tab-names '>
        {tabs.map((tab, index) =>
          <div
            role='button'
            key={index}
            tabIndex={0}
            className={getTabClassName(index)}
            onClick={() => setActiveTab(index)}
            onKeyDown={() => setActiveTab(index)}
          >
            <div className='tab-name-text'>Pflegegrad {index + 1}</div>
            <div className='tab-name-number'>{index + 1}</div>
          </div>
        )}
      </div>
      {tabs.map((tab, index) =>
        <Tab
          key={index} {...tab}
          activeTab={index === activeTab && true}
        />
      )}
    </SectionWrapper>
  </>
}

export default Tabs

Tabs.propTypes = {
  header: PropTypes.string,
  headline: PropTypes.string,
  tabs: PropTypes.arrayOf(TabPropType)
}

Tabs.defaultProps = {
  header: '',
  headline: '',
  tabs: []
}
