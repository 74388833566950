import React from 'react'
import logo from '../../images/logoSenprimaWhite.svg'
import PropTypes from 'prop-types'
import { LinkButtonPropType } from '../propTypes'

const WelcomingSectionMobile = ({ header, description, buttons, information }) =>
  <div className='welcoming-screen-mobile'>
    <div className='welcoming-screen-mobile-content'>
      <img
        src={logo}
        alt='Senprima logo'
      />
      <div className='welcoming-screen-mobile__header'>{header}</div>
      <div className='welcoming-screen-mobile__body'>{description}</div>
      <div className='welcoming-screen-mobile-buttons'>
        {buttons.map((button) =>
          <a
            className='button'
            key={button?.label}
            href={button?.href}
          >
            {button?.label}
          </a>)}
      </div>
    </div>
    <div className='welcoming-screen-mobile__information'>
      <div>{information}</div>
    </div>
  </div>

export default WelcomingSectionMobile

WelcomingSectionMobile.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  buttons: PropTypes.arrayOf(LinkButtonPropType),
  information: PropTypes.string
}

WelcomingSectionMobile.defaultProps = {
  header: '',
  description: '',
  buttons: [],
  information: ''
}
