import React from 'react'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import useMap from './useMap'

const Map = ({ currentLocationCords }) => {
  useMap(currentLocationCords)

  return <div
    id='map'
    className='home-page-map'
  />
}

export default Map
