import React from 'react'
import PropTypes from 'prop-types'
import TabService from './TabService'
import { ServicePropType, StrapiImageSharpPropType } from '../propTypes'
import { GatsbyImage } from 'gatsby-plugin-image'

const Tab = ({ image, description, additionalServices, basicServices, activeTab }) => {
  const gatsbyImageData = image?.localFile?.childImageSharp?.gatsbyImageData

  return <div className={`tab ${activeTab && 'tab--active'}`}>
    <div className='tab__header'>
      {description}
    </div>
    <div className='tab-row'>
      <div className='tab-row-left'>
        <div className='tab-row-left__additional-services-header'>Zusätzliche Leistungen</div>
        {additionalServices.map((service, index) => <TabService key={index} {...service} />
        )}
        <div className='tab-row-left__basic-services-header'>Grundleistungen</div>
        {basicServices.map((service, index) => <TabService key={index} {...service} />
        )}
      </div>
      <div className='tab-row-right'>
        <GatsbyImage
          className='tab-row-right__image'
          alt={image?.alternativeText}
          image={gatsbyImageData}
        />
      </div>
    </div>
  </div>
}

export default Tab

Tab.propTypes = {
  image: StrapiImageSharpPropType,
  description: PropTypes.string,
  additionalServices: PropTypes.arrayOf(ServicePropType),
  basicServices: PropTypes.arrayOf(ServicePropType),
  activeTab: PropTypes.bool,
}

Tab.defaultProps = {
  image: {},
  description: '',
  additionalServices: [],
  basicServices: [],
  activeTab: false,
}
