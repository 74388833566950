import React from 'react'
import WelcomingSectionMobile from './WelcomingSectionMobile'
import WelcomingSectionDesktop from './WelcomingSectionDesktop'

const WelcomingSection = (props) =>
  <>
    <WelcomingSectionDesktop {...props}/>
    <WelcomingSectionMobile {...props}/>
  </>

export default WelcomingSection

