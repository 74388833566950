import React from 'react'
import { Controller, Scene } from 'react-scrollmagic'
import PropTypes from 'prop-types'

const StepArrow = ({ image }) =>
  <Controller>
    <Scene
      duration={300}
      reverse={false}
    >
      {(progress => (
        <img
          style={{ opacity: progress }}
          className='arrow'
          src={image}
          alt='Arrow pointing down'
        />
      ))}
    </Scene>
  </Controller>

export default StepArrow

StepArrow.propTypes = {
  image: PropTypes.string.isRequired,
}
