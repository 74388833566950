import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import Paragraph from '../Paragraph/Paragraph'
import PropTypes from 'prop-types'

const Introduction = ({ header, description }) =>
  <>
    <div className='gradient-bar'/>
    <SectionWrapper
      backgroundColor='teal'
      backgroundColorMd='white'
      boxShadow
      className='introduction'
    >
      <div className='introduction__header'>{header}</div>
      <Paragraph
        text={description}
        className='introduction__body'
      />
    </SectionWrapper>
  </>

export default Introduction

Introduction.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string
}

Introduction.defaultProps = {
  header: '',
  description: ''
}
